import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText);

const pre_tl = gsap.timeline({
    paused: true
});

const preTitle_tl = gsap.timeline({
    paused: true,
    repeat: -1
});

const init = () => {

    document.querySelectorAll('.pre__title').forEach((el) => {
        
        let text = new SplitText(el, {type:"words,chars"});
        let chars = text.chars;
        
        preTitle_tl
        //.add((el => { console.log(el); el.classList.add('visible'); })
        .set(el, {display: 'block'})
        .from(".pre__container .container", {duration: 0.1, autoAlpha: 1})
        .from(chars, {duration: 0.6, autoAlpha: 0 , y: '50%',  ease: "power2.out", stagger: 0.01})
        .to(chars, {duration: 0.6, autoAlpha: 0 , y: '-50%',  ease: "power2.in", stagger: 0.01}, '+=0.8')
        .set(el, {display: 'none'});
    });

    preTitle_tl.play();

    pre_tl
    .to(".pre__container", {autoAlpha:0, duration: 0.8})
    .to(".pre__bg1", {morphSVG: ".pre__bg2", duration: 1, ease: "power4.in"})
    .to(".pre__bg1", {morphSVG: ".pre__bg3", duration: 0.4, ease: "power2.out"})
    .to('.pre', {autoAlpha:0, duration: 0.1}, '+=1')
    .addLabel("introRemoved");
}

const close = () => {

    pre_tl.tweenFromTo(0, "introRemoved");
    preTitle_tl.pause();
}

init();

window.addEventListener('load', () => {

    setTimeout(() => {
        close();
    }, 6000);
})

export default {init, close};